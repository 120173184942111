import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors, GridWrapper } from "../theme";
import bgMLetter from "../assets/bg-mletter.svg";

const Container = styled(GridWrapper)`
  align-items: center;
  background: url(${bgMLetter}) no-repeat left center;
  display: flex;
  justify-content: center;
  margin: 60px auto;
  min-height: 440px;

  @media (max-width: 767px) {
    text-align: center;
  }

  > div {
    max-width: 600px;
  }

  h1 {
    color: black;
    font-size: 4.8rem;
    margin-bottom: 20px;
    text-transform: uppercase;

    @media (max-width: 1023px) {
      font-size: 4rem;
    }
  }

  p {
    font-size: 2rem;
    margin-bottom: 12px;

    @media (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  a {
    color: ${colors.primaryColor};
    transition: 0.3s;

    :hover {
      color: ${colors.primaryHover};
    }
  }
`;

function Error({ message }) {
  return (
    <Container>
      <div>
        <h1>Une erreur est survenue</h1>
        <p>Message d'erreur : "{message}".</p>
        <p>
          Veuillez recharger la page ou retourner sur{" "}
          <Link to="/">la page d'accueil</Link>.
        </p>
      </div>
    </Container>
  );
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Error;
