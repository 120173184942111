import React from "react";
import loadable from "@loadable/component";

import { Spinner } from "../../../theme";

export default {
  "st8-Yg2BSDWB7dcMPMW6sA": loadable(
    () => import("./covidHerdImmunity/CovidHerdImmunity"),
    {
      fallback: <Spinner />,
    }
  ),
  PtqelwZ3SmalFijh_IZrvA: loadable(
    () => import("./covidWorldResponse/CovidWorldResponse"),
    {
      fallback: <Spinner />,
    }
  ),
};
