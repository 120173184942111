import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import Youtube from "react-youtube";

import { getSubscription } from "../../configureStore";
import { getYoutubeVideoId } from "../../utilities";
import { gradients, fadeIn } from "../../theme";
import btnPlay from "../../assets/btn-play-white.svg";
import btnPlayHover from "../../assets/btn-play-red.svg";

const Container = styled.div`
  box-shadow: 0 4px 8px rgba(46, 68, 82, 0.2);
  ${(props) => (props.isPlayerReady ? "cursor: pointer;" : "")}
  height: 551px;
  margin: auto;
  position: relative;
  width: 980px;

  @media (max-width: 1599px) {
    height: 528px;
    width: 890px;
  }

  @media (max-width: 1023px) {
    height: 353px;
    width: 595px;
  }

  @media (max-width: 767px) {
    height: 190px;
    width: 320px;
  }

  :after {
    background: url(${(props) => props.overlay}) center,
      ${gradients.redToPurple("72deg")};
    background-size: cover;
    ${(props) => (props.showOverlay ? 'content: "";' : "")}
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  button {
    animation: ${fadeIn} 0.3s;
    border: 2px solid white;
    border-radius: 50%;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    height: 124px;
    left: calc(50% - 62px);
    overflow: hidden;
    position: absolute;
    top: calc(50% - 62px);

    transition: 0.3s;
    width: 124px;
    z-index: 100;

    @media (max-width: 767px) {
      height: 60px;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      width: 60px;
    }

    :before {
      background: url(${(props) => props.overlay}) center,
        ${gradients.redToPurple("72deg")};
      background-size: cover;
      content: "";
      display: block;
      filter: blur(10px) brightness(1.2);
      height: 551px;
      left: -428px;
      position: absolute;
      top: -213px;
      transition: 0.3s;
      width: 980px;
      z-index: -1;

      @media (max-width: 1599px) {
        height: 528px;
        left: -383px;
        top: -202px;
        width: 890px;
      }

      @media (max-width: 1023px) {
        height: 353px;
        left: -235px;
        top: -114px;
        width: 595px;
      }

      @media (max-width: 767px) {
        height: 190px;
        left: -130px;
        top: -65px;
        width: 320px;
      }
    }

    :after {
      background: url(${btnPlay}) center no-repeat;
      background-size: 26px;
      content: "";
      display: block;
      height: 100%;
      left: 5px;
      position: absolute;
      top: 0;
      transition: 0.3s;
      width: 100%;

      @media (max-width: 767px) {
        background-size: 15px;
        left: 1px;
      }
    }
  }

  :hover button {
    transform: scale(1.3);

    :before {
      background: white;
    }

    :after {
      background-image: url(${btnPlayHover});
    }
  }
`;

const Player = styled.div`
  display: ${(props) => (props.showPlayer ? "block" : "none")};

  &,
  div {
    height: 100%;
    width: 100%;
  }
`;

function VideoEmbed({ subscription, video }) {
  const player = useRef();
  const [showPlayer, setShowPlayer] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const peertubePlayerRef = useRef();
  const [peertubePlayer, setPeertubePlayer] = useState(undefined);
  const [youtubePlayer, setYoutubePlayer] = useState(undefined);

  useEffect(() => {
    // Smooth animation of pages with videoEmbed in
    const timeout = setTimeout(() => setShowPlayer(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (
      subscription &&
      subscription.payment_status === "ACTIVE" &&
      video.attachments_by_keys.video_peertube
    ) {
      player.current = (
        <iframe
          ref={peertubePlayerRef}
          title={video.title}
          src={
            video.attachments_by_keys.video_peertube.value +
            "?peertubeLink=0&p2p=0&api=1"
          }
          allow="autoplay; fullscreen"
          width="100%"
          height="100%"
          frameborder="0"
        />
      );

      async function loadPeertubePlayer() {
        const PeerTubePlayer = window["PeerTubePlayer"];
        const loadingPlayer = new PeerTubePlayer(peertubePlayerRef.current);
        await loadingPlayer.ready;
        setPeertubePlayer(loadingPlayer);
        setIsPlayerReady(true);
      }
      loadPeertubePlayer();
    } else if (video.attachments_by_keys.video_youtube) {
      const videoId = getYoutubeVideoId(
        video.attachments_by_keys.video_youtube.value
      );
      if (videoId) {
        player.current = (
          <Youtube
            videoId={videoId}
            onReady={handleReady}
            opts={{
              width: "100%",
              height: "100%",
              playerVars: {
                color: "white",
                controls: 2,
                rel: 0,
              },
            }}
          />
        );

        function handleReady(e) {
          setYoutubePlayer(e.target);
          setIsPlayerReady(true);
        }
      }
    }
  });

  function hideOverlay() {
    if (isPlayerReady) {
      if (peertubePlayer) {
        peertubePlayer.play();
      } else if (youtubePlayer) {
        youtubePlayer.playVideo();
      }
      setShowOverlay(false);
    }
  }

  return (
    <Container
      overlay={video.thumb_video_page_url}
      showOverlay={showOverlay}
      isPlayerReady={isPlayerReady}
      onClick={hideOverlay}
    >
      <Player showPlayer={showPlayer}>{player.current}</Player>
      {isPlayerReady && showOverlay && <button onClick={hideOverlay} />}
    </Container>
  );
}

VideoEmbed.propTypes = {
  subscription: PropTypes.shape({
    payment_status: PropTypes.string.isRequired,
  }),
  video: PropTypes.shape({
    title: PropTypes.string.isRequired,
    thumb_video_page_url: PropTypes.string.isRequired,
    attachments_by_keys: PropTypes.shape({
      video_peertube: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
      video_youtube: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

export default connect((state) => ({
  subscription: getSubscription(state),
}))(VideoEmbed);
