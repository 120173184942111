import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { menuOffset, mobileMenuOffset, colors, gradients } from "../../theme";

const Wrapper = styled.div`
  height: 73px;

  @media (max-width: 767px) {
    height: 71px;
  }
`;

const Header = styled.header`
  background: white;
  box-shadow: 0 3px 8px rgba(46, 68, 82, 0.25);
  position: fixed;
  text-align: center;
  top: ${menuOffset};
  width: 100%;
  z-index: 9999;

  @media (max-width: 767px) {
    padding-top: 13px;
    top: ${mobileMenuOffset};
  }

  nav {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  h1 {
    color: ${colors.gray};
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 2.5rem;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 1.5rem;
      line-height: 1.6rem;
    }
  }

  a {
    color: ${colors.lightTextColor};
    display: block;
    font-size: 1.3rem;
    height: 48px;
    line-height: 4.8rem;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 114px;

    @media (max-width: 767px) {
      font-size: 1.1rem;
      height: 42px;
      line-height: 4.2rem;
    }

    :after {
      background: ${gradients.purpleToPink()};
      bottom: -4px;
      content: "";
      display: block;
      height: 4px;
      left: 0;
      position: absolute;
      transition: 0.3s;
      width: 100%;
    }

    :hover,
    &.active {
      font-weight: 700;

      :after {
        bottom: 0;
      }
    }
  }
`;

function ShowMenu({ slug, category }) {
  function getSchemeSlug(scheme) {
    switch (scheme) {
      case "PROGRAM":
        return "emissions";
      case "PODCAST":
        return "podcasts";
      default:
        return "indefini";
    }
  }

  return (
    <Wrapper>
      <Header>
        <h1>{category.label}</h1>
        <nav role="navigation" aria-label="Sous-menu de l'émission">
          {slug && (
            <NavLink
              activeClassName="active"
              to={(location) => location.pathname}
            >
              L'émission
            </NavLink>
          )}
          {category.attributes.audio_enabled ? (
            <>
              <NavLink
                activeClassName="active"
                to={`/emissions/${category.slug}`}
              >
                Les vidéos
              </NavLink>
              <NavLink
                activeClassName="active"
                to={`/podcasts/${category.slug}`}
              >
                Les podcasts
              </NavLink>
            </>
          ) : (
            <NavLink
              activeClassName="active"
              to={`/${getSchemeSlug(category.scheme)}/${category.slug}`}
            >
              Tous les épisodes
            </NavLink>
          )}
        </nav>
      </Header>
    </Wrapper>
  );
}

ShowMenu.propTypes = {
  slug: PropTypes.string,
  category: PropTypes.shape({
    label: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    scheme: PropTypes.oneOf(["PROGRAM", "PODCAST"]).isRequired,
    attributes: PropTypes.object.isRequired,
  }).isRequired,
};

export default ShowMenu;
