import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import loader from "../assets/loader-white.mp4";

const Container = styled.div`
  text-align: center;

  video {
    padding: 30px 0 60px;
  }
`;

function Loader({ style }) {
  return (
    <Container>
      <video style={style} src={loader} autoPlay loop muted playsInline />
    </Container>
  );
}

Loader.propTypes = {
  style: PropTypes.object
};

export default Loader;
