import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import Img from "react-image";

import {
  parseHTML,
  getAuthorsPresentation,
  formatDate,
  getPodcastLink,
} from "../../utilities";
import {
  colors,
  gradients,
  GridWrapper,
  Date,
  useMaxTabletPortrait,
} from "../../theme";
import VideoEmbed from "./VideoEmbed";
import Presentation from "../Presentation";
import AuthorCard from "../author/AuthorCard";
import Wysiwyg from "../Wysiwyg";
import Backstage from "../Backstage";
import placeholderImg from "../../assets/placeholder-video-item.png";
import btnPodcast from "../../assets/btn-podcast-red.svg";
import btnReadmore from "../../assets/btn-readmore-red.svg";

const VideoBackground = styled.div`
  height: 530px;
  overflow: hidden;
  position: absolute;
  width: 100%;

  @media (max-width: 1599px) {
    height: 508px;
  }

  @media (max-width: 1023px) {
    height: 343px;
  }

  @media (max-width: 767px) {
    height: 280px;
  }

  :before,
  :after {
    background: url(${(props) => props.background}) center no-repeat;
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
  }

  :before {
    filter: blur(10px) brightness(0.6) contrast(134%);
    height: 104%;
    left: -2%;
    top: -2%;
    width: 104%;
  }

  :after {
    background-image: ${gradients.redToPurple("72deg")};
    filter: opacity(0.75) saturate(140%);
    height: 100%;
    width: 100%;
  }
`;

const ProgramPresentation = styled(Presentation)`
  margin: 44px auto !important;

  > a {
    padding: 8px 60px;
  }
`;

const Body = styled(GridWrapper)`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding-right: 99px;

  @media (max-width: 1599px) {
    padding-right: 0;
  }
`;

const SidebarBox = styled.div`
  border-bottom: 1px solid ${colors.borderColor};
  border-top: 1px solid ${colors.borderColor};
  margin-bottom: 50px;
  padding: 25px 0 35px;
  text-align: center;
  width: 184px;

  @media (max-width: 1599px) {
    width: 164px;
  }

  h1 {
    color: black;
    font-size: 2rem;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  a {
    box-shadow: 4px 8px 10px rgba(46, 68, 82, 0.2);
    display: block;
    position: relative;

    :before,
    :after {
      background: ${gradients.toDarkPurple("-30deg")};
      background-size: cover;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    :after {
      background: rgba(16, 0, 43, 0.5);
      opacity: 0;
      transition: 0.3s;
    }

    :hover {
      :after {
        opacity: 1;
      }

      div {
        transform: translate(-28px, -28px) scale(1.25);
      }
    }
  }

  img {
    display: block;
    width: 100%;
  }

  div {
    border-radius: 50%;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    height: 56px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-28px, -28px);
    transition: 0.3s;
    width: 56px;
    z-index: 1;
  }

  p {
    font-size: 1.4rem;
  }
`;

const Podcast = styled(SidebarBox)`
  div {
    background: center no-repeat url(${btnPodcast}) white;
    background-size: 18px;
  }

  p {
    margin-top: 15px;
  }
`;

const Topics = styled(SidebarBox)`
  div {
    background: center no-repeat url(${btnReadmore}) white;
    background-size: 21px;
  }

  a {
    margin-top: 25px;
  }

  h2 {
    color: black;
    font-size: 1.7rem;
    font-weight: 500;
    margin-top: 15px;
  }
`;

const Main = styled.section`
  color: black;
  width: 778px;

  @media (max-width: 1599px) {
    width: 640px;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }

  h1 {
    font-size: 3.8rem;
    font-weight: 500;
    line-height: 5.4rem;
    margin: 18px 0 44px;

    @media (max-width: 1023px) {
      font-size: 3.5rem;
      line-height: 4.5rem;
      margin-bottom: 38px;
    }

    @media (max-width: 767px) {
      font-size: 2.5rem;
      line-height: 3.5rem;
      margin-bottom: 35px;
    }

    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

const Headline = styled.p`
  color: black;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 2.8rem;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    font-size: 2rem;
  }
`;

const Credits = styled.p`
  border-left: 3px solid ${colors.primaryColor};
  color: ${colors.gray};
  font-style: italic;
  padding-left: 20px;
`;

const AuthorPresentation = styled(Presentation)`
  margin-bottom: 60px;
  width: auto;

  @media (max-width: 767px) {
    margin-bottom: 50px;
  }

  > a {
    left: 40px;
    transform: none;
  }

  > p {
    font-size: 1.7rem;
    line-height: 2.8rem;

    @media (max-width: 1023px) {
      width: 415px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

function VideoPage({ video, StaticContent }) {
  const isMaxTabletPortrait = useMaxTabletPortrait();

  let author;
  if (isMaxTabletPortrait) {
    const authors = video.authors;

    if (!authors.length) {
      Sentry.captureMessage(`No author on ${video.title}`);
    } else if (authors.length === 1) {
      const authorsPresentation = getAuthorsPresentation(authors);

      author = (
        <AuthorPresentation
          title={`Par ${authorsPresentation.names}`}
          titleLink={`/auteurs/${authors[0].slug}`}
          titleColor={colors.authorColor}
          content={authorsPresentation.description}
          shareText={video.title}
        />
      );
    }
  }

  return (
    <article>
      <VideoBackground background={video.thumb_hero_url} />
      <VideoEmbed video={video} />
      <ProgramPresentation
        title={video.primary_category.label}
        titleLink={`/emissions/${video.primary_category.slug}`}
        titleColor={colors.programColor}
        content={video.primary_category.description}
        shareText={video.title}
      />
      <Body>
        {!isMaxTabletPortrait && (
          <aside>
            <AuthorCard
              authors={video.authors}
              tags={video.story_tags}
              title={video.title}
            />
            {video.scheme === "VIDEO_AUDIO" && (
              <Podcast>
                <h1>Podcast</h1>
                <Link to={getPodcastLink(video.canonical_url)}>
                  <Img
                    src={video.thumb_hero_url}
                    alt={video.title}
                    loader={<img src={placeholderImg} alt={video.title} />}
                    unloader={<img src={placeholderImg} alt={video.title} />}
                  />
                  <div />
                </Link>
                <p>Cette émission est aussi disponible en version audio.</p>
              </Podcast>
            )}
            {video.topics.length > 0 && (
              <Topics>
                <h1>Dossiers</h1>
                <p>Vous pouvez retrouver cette émission dans nos dossiers.</p>
                {video.topics.map((topic) => (
                  <React.Fragment key={topic.slug}>
                    <Link to={`/dossiers/${topic.slug}`}>
                      <Img
                        src={topic.thumb_hero_url}
                        alt={topic.title}
                        loader={<img src={placeholderImg} alt={topic.title} />}
                        unloader={
                          <img src={placeholderImg} alt={topic.title} />
                        }
                      />
                      <div />
                    </Link>
                    <h2>{parseHTML(topic.rich_title)}</h2>
                  </React.Fragment>
                ))}
              </Topics>
            )}
          </aside>
        )}
        <Main>
          <Date>{formatDate(video.published_at)}</Date>
          <h1>{parseHTML(video.rich_title)}</h1>
          <Wysiwyg>
            {video.headline && <Headline>{video.headline}</Headline>}
            {StaticContent ? <StaticContent /> : parseHTML(video.content)}
            {video.cover_image_credits && (
              <Credits>{video.cover_image_credits}</Credits>
            )}
            {video.backstage && <Backstage>{video.backstage}</Backstage>}
          </Wysiwyg>

          {isMaxTabletPortrait && author}
        </Main>
      </Body>
    </article>
  );
}

VideoPage.propTypes = {
  video: PropTypes.shape({
    thumb_hero_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    rich_title: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    authors: PropTypes.array.isRequired,
    story_tags: PropTypes.array.isRequired,
    primary_category: PropTypes.shape({
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    headline: PropTypes.string,
    content: PropTypes.string.isRequired,
    cover_image_credits: PropTypes.string,
    backstage: PropTypes.string,
    scheme: PropTypes.oneOf(["VIDEO", "VIDEO_AUDIO"]).isRequired,
    canonical_url: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        thumb_hero_url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        rich_title: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  StaticContent: PropTypes.object,
};

export default VideoPage;
