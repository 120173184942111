import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Img from "react-image";

import {
  getStoryLink,
  getPodcastLink,
  formatDate,
  parseHTML,
} from "../../utilities";
import { colors, gradients, Date, FadeInPage } from "../../theme";
import Tags from "../Tags";
import placeholderImg from "../../assets/placeholder-video-item.png";
import btnReadmore from "../../assets/btn-readmore-red.svg";
import btnPlay from "../../assets/btn-play-red.svg";
import btnPodcast from "../../assets/btn-podcast-red.svg";

const Container = styled(FadeInPage)`
  width: 379px;

  a {
    text-decoration: none;
  }

  h3 {
    color: ${colors.textColor};
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 2.8rem;
    margin: 14px 0 22px;
    padding-right: 5px;
    transition: 0.2s;

    :hover {
      color: ${colors.primaryColor};
    }
  }
`;

const Image = styled(Link)`
  box-shadow: 8px 12px 14px rgba(46, 68, 82, 0.2);
  display: block;
  position: relative;

  :before,
  :after {
    background: ${gradients.toDarkPurple("-30deg")};
    background-size: cover;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  :after {
    background: rgba(16, 0, 43, 0.5);
    opacity: 0;
    transition: 0.3s;
  }

  :hover {
    :after {
      opacity: 1;
    }

    h2 + div {
      transform: translate(-28px, -23px) scale(1.5);
    }
  }

  img {
    display: block;
    width: 100%;
  }

  time {
    left: 15px;
    position: absolute;
    top: 18px;
  }

  h2 {
    color: white;
    font-size: 2.4rem;
    font-weight: 500;
    left: 15px;
    position: absolute;
    top: 45px;
    text-transform: uppercase;
  }
`;

const SchemeIco = styled.div`
  background: center no-repeat white;
  background-image: ${(props) => {
    switch (props.scheme) {
      case "STORY":
        return `url(${btnReadmore})`;
      case "AUDIO":
        return `url(${btnPodcast})`;
      default:
        return `url(${btnPlay})`;
    }
  }};
  background-position: ${(props) => {
    switch (props.scheme) {
      case "STORY":
        return "center";
      case "AUDIO":
        return "center";
      default:
        return "calc(50% + 2px) center";
    }
  }};
  background-size: ${(props) => {
    switch (props.scheme) {
      case "STORY":
        return "18px";
      case "AUDIO":
        return "15px";
      default:
        return "14px";
    }
  }};
  border-radius: 50%;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  height: 56px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-28px, -23px);
  transition: 0.3s;
  width: 56px;
  z-index: 1;
`;

function ContentItem({ content, constrainedScheme }) {
  return (
    <Container as="article">
      <Image
        to={
          constrainedScheme === "AUDIO"
            ? getPodcastLink(content.canonical_url)
            : getStoryLink(content.canonical_url)
        }
      >
        <Img
          src={content.thumb_video_caroussel_list_url}
          alt={content.title}
          loader={<img src={placeholderImg} alt={content.title} />}
          unloader={<img src={placeholderImg} alt={content.title} />}
        />
        <Date>{formatDate(content.published_at)}</Date>
        <h2>{content.primary_category.label}</h2>
        <SchemeIco scheme={constrainedScheme || content.scheme} />
      </Image>
      <Link
        to={
          constrainedScheme === "AUDIO"
            ? getPodcastLink(content.canonical_url)
            : getStoryLink(content.canonical_url)
        }
      >
        <h3>{parseHTML(content.rich_title)}</h3>
      </Link>
      <Tags tags={content.story_tags} />
    </Container>
  );
}

ContentItem.propTypes = {
  content: PropTypes.shape({
    canonical_url: PropTypes.string.isRequired,
    scheme: PropTypes.string.isRequired,
    thumb_video_caroussel_list_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    rich_title: PropTypes.string.isRequired,
    primary_category: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    story_tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    published_at: PropTypes.string.isRequired,
  }).isRequired,
  constrainedScheme: PropTypes.oneOf(["VIDEO", "STORY", "AUDIO"]),
};

export default ContentItem;
