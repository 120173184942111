import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { GridWrapper } from "../../theme";
import Loader from "../Loader";
import NotFound from "../NotFound";
import Error from "../Error";
import ContentItem from "./ContentItem";

const List = styled(GridWrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ::after {
    content: "";
    width: 379px;

    @media (max-width: 1599px) {
      width: 286px;
    }
  }

  article {
    margin-bottom: 35px;

    @media (max-width: 1599px) {
      width: 286px;

      time {
        font-size: 1.3rem;
        left: 10px;
        top: 10px;
      }

      h2 {
        font-size: 2.2rem;
        left: 10px;
        top: 35px;
      }

      h3 {
        font-size: 1.9rem;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

function ContentList({
  contentList: { isFetching, error, content },
  constrainedSchemeItem,
}) {
  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    if (error === "404") {
      return <NotFound />;
    }
    return <Error message={error} />;
  }

  return (
    <List>
      {content.map((item) => (
        <ContentItem
          key={item.slug}
          content={item}
          constrainedScheme={constrainedSchemeItem}
        />
      ))}
    </List>
  );
}

ContentList.propTypes = {
  contentList: PropTypes.shape({
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  constrainedSchemeItem: PropTypes.oneOf(["VIDEO", "STORY", "AUDIO"]),
};

export default ContentList;
