import React from "react";
import PropTypes from "prop-types";

import { Heading } from "../theme";
import ContentList from "./content/ContentList";

function Contextual({ content, className }) {
  return (
    <section className={className}>
      <Heading>
        <h1>Vous aimerez aussi</h1>
      </Heading>
      <ContentList contentList={{ content }} />
    </section>
  );
}

Contextual.propTypes = {
  className: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Contextual;
