import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { getVideoPage } from "../configureStore";
import { fetchVideoPage } from "../configureStore/videoPage";
import { getHelmet, getSlugId } from "../utilities";
import { FromTopPage, GridWrapper, Banner, Button } from "../theme";
import PageLoader from "../components/loader/PageLoader";
import NotFound from "../components/NotFound";
import Error from "../components/Error";
import ShowMenu from "../components/menu/ShowMenu";
import VideoPage from "../components/video/VideoPage";
import Contextual from "../components/Contextual";
import JoinUs from "../components/JoinUs";
import statics from "../components/video/statics";

const ContextualContent = styled.section`
  margin-top: 90px;

  @media (max-width: 1023px) {
    margin-top: 45px;
  }
`;

const ProgramBanner = styled(Banner)`
  > div > div {
    width: 80%;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }
`;

class Video extends Component {
  static fetchInitialData({ slug, previewToken }) {
    return [fetchVideoPage(slug, previewToken)];
  }

  componentDidMount() {
    this.fetchContent();
  }

  componentDidUpdate() {
    this.fetchContent();
  }

  fetchContent() {
    const { videoPage, dispatch, match } = this.props;
    if (!videoPage.isFetching && !videoPage.error && !videoPage.isLoaded) {
      dispatch(fetchVideoPage(match.params.slug, match.params.previewToken));
    }
  }

  render() {
    const { error, isLoaded, video } = this.props.videoPage;
    const StaticContent = statics[getSlugId(this.props.match.params.slug)];

    if (error) {
      if (error === "404") {
        return <NotFound />;
      }

      return <Error message={error} />;
    }

    if (isLoaded) {
      const microdata = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        isAccessibleForFree: "http://schema.org/True",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": video.canonical_url,
        },
        headline: video.title,
        image: {
          "@type": "ImageObject",
          url: video.thumb_hero_url,
          width: 1920,
          height: 960,
        },
        datePublished: video.published_at,
        dateModified: video.published_at,
        author: video.authors.map((author) => ({
          "@type": "Person",
          name: author.screenname,
          url: `${process.env.RAZZLE_HOSTNAME}/auteurs/${author.slug}`,
        })),
        publisher: {
          "@type": "Organization",
          name: "Le Média",
          logo: {
            "@type": "ImageObject",
            url: "http://ressources.lemediatv.fr/logo-microdata.png",
            width: 300,
            height: 60,
          },
          url: "https://lemediatv.fr",
        },
        description: video.headline_or_extract_medium,
        keywords: video.story_tags.map((tag) => tag.label).join(", "),
        video: {
          "@type": "VideoObject",
          contentUrl: video.attachments_by_keys.video_youtube.value,
          description: video.headline_or_extract_medium,
          name: video.title,
          thumbnailUrl: video.thumb_video_page_url,
          uploadDate: video.published_at,
        },
      };

      return (
        <>
          <Helmet>
            {getHelmet(
              video.title,
              video.headline_or_extract_medium,
              this.props.match.url,
              video.thumb_hero_url
            )}

            <script type="application/ld+json">
              {JSON.stringify(microdata)}
            </script>
          </Helmet>
          <ShowMenu
            slug={this.props.match.params.slug}
            category={video.primary_category}
          />
          <FromTopPage>
            <VideoPage video={video} StaticContent={StaticContent} />

            <ProgramBanner>
              <GridWrapper>
                <div>
                  <h1>{video.primary_category.label}</h1>
                  <p>{video.primary_category.description}</p>
                </div>
                <Button
                  as={Link}
                  to={`/emissions/${video.primary_category.slug}`}
                >
                  En voir +
                </Button>
              </GridWrapper>
            </ProgramBanner>
            <ContextualContent
              as={Contextual}
              content={video.contextual_stories}
            />
            <JoinUs />
          </FromTopPage>
        </>
      );
    }

    return <PageLoader />;
  }
}

Video.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      previewToken: PropTypes.string,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  videoPage: PropTypes.shape({
    error: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    video: PropTypes.shape({
      title: PropTypes.string.isRequired,
      headline_or_extract_medium: PropTypes.string.isRequired,
      published_at: PropTypes.string.isRequired,
      canonical_url: PropTypes.string.isRequired,
      thumb_hero_url: PropTypes.string.isRequired,
      thumb_video_page_url: PropTypes.string.isRequired,
      primary_category: PropTypes.shape({
        label: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
      contextual_stories: PropTypes.arrayOf(PropTypes.object).isRequired,
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          screenname: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      story_tags: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ).isRequired,
      attachments_by_keys: PropTypes.shape({
        video_youtube: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
      }).isRequired,
    }),
  }).isRequired,
};

export default connect((state, { match }) => ({
  videoPage: getVideoPage(state, match.params.slug),
}))(Video);
