import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../theme";

const Container = styled.div`
  background: ${colors.lightGray};
  color: ${colors.textColor};
  margin: 50px 0;
  max-width: 778px;
  padding: 35px 30px;

  h1 {
    color: ${colors.darkBlue};
    font-size: 3.2rem;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
`;

function Backstage({ children }) {
  return (
    <Container>
      <h1>En coulisses</h1>
      <div>{children}</div>
    </Container>
  );
}

Backstage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Backstage;
