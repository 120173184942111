import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { gradients, colors } from "../theme";
import bgHeader from "../assets/bg-notfound.svg";
import imgLost from "../assets/img-notfound.png";

const Container = styled.div`
  background: ${gradients.purpleToPink("to bottom")};
  color: white;
  padding-bottom: 167px;
  text-align: center;

  h1 {
    color: rgba(255, 255, 255, 0.2);
    font-size: 40rem;
    font-weight: 50;
    letter-spacing: 1.5rem;
    padding: 86px 0 5px;
    position: relative;
    z-index: 0;

    @media (max-width: 767px) {
      font-size: 20rem;
      padding-bottom: 40px;
    }

    :before {
      background: url(${bgHeader});
      content: "";
      display: block;
      height: 237px;
      position: absolute;
      top: 210px;
      width: 100%;
      z-index: -1;

      @media (max-width: 767px) {
        top: 95px;
      }
    }

    :after {
      background: url(${imgLost}) no-repeat center;
      content: "";
      display: block;
      height: 100%;
      left: 20px;
      position: absolute;
      top: 0;
      width: calc(100% - 20px);

      @media (max-width: 767px) {
        background-size: 60%;
      }
    }
  }

  h2 {
    font-size: 4.2rem;
    font-weight: 100;
    letter-spacing: -0.05rem;
    margin: 0 auto 32px;
    text-transform: uppercase;
    max-width: calc(100% - 80px);
  }

  p {
    line-height: 2.4rem;
    margin: 0 auto 2.4rem;
    max-width: calc(100% - 80px);
  }
`;

const Form = styled.form`
  margin-top: 27px;

  input {
    background: rgba(243, 243, 243, 0.2);
    border: 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    color: white;
    font-size: 2rem;
    height: 44px;
    margin-right: 15px;
    opacity: 0.6;
    padding: 0 14px;
    vertical-align: middle;
    transition: 0.3s;
    width: 361px;

    :hover:not(:disabled),
    :focus:not(:disabled) {
      opacity: 1;
    }

    ::placeholder {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  button {
    background: black;
    color: white;
    font-weight: 600;
    height: 44px;
    text-transform: uppercase;
    transition: 0.3s;
    vertical-align: middle;
    width: 163px;

    :hover {
      background: ${colors.purple};
    }
  }

  @media (max-width: 767px) {
    input,
    button {
      width: calc(100% - 30px);
    }

    input {
      margin: 0 0 15px;
    }
  }
`;

function SearchForm() {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const onSubmit = (data) => history.push(`/recherche/${data.search}`);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <input
        aria-label="Rechercher une autre page"
        type="text"
        placeholder="Écrire ici ..."
        name="search"
        ref={register({ required: true })}
      />

      <button type="submit">Rechercher</button>
    </Form>
  );
}

function NotFound() {
  return (
    <Container>
      <h1>404</h1>
      <h2>On dirait que vous êtes perdu ...</h2>
      <p>
        Vous pensiez que cette mission vers la Lune serait achevée en 6 mois.
        Votre voisin vous a proposé de garder votre chien.
        <br />
        Votre prof. d'histoire de terminale serait impressionné : lui qui ne
        cessait de vous répéter que vous ne seriez bon à rien dans la vie.
        <br />
        Vous vouliez lui prouver le contraire. Vous êtes monté à bord de ce
        vaisseau spatial, et vous l'avez fait ce voyage dans l'espace.
        <br />
        Mais maintenant, vous voilà perdu dans le vide sidéral, loin de votre
        vaisseau, sans aucun moyen de faire le chemin de retour.
      </p>
      <p>
        Votre chien sera tellement triste. Votre prof d'histoire dira qu'il
        avait vu juste ...
        <br />
        <strong>Effectuez vite une recherche pour sortir d'ici&nbsp;:</strong>
      </p>
      <SearchForm />
    </Container>
  );
}

export default NotFound;
